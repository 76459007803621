import { t } from '@zupr/i18n'
import { Field } from '@zupr/types/form'
import classnames from 'classnames'
import React from 'react'

import WarningIcon from '../../../../svg/warning.svg'
import Trans from '../../components/trans'
import ErrorBoundary from '../../error/boundary'
import Label, { LabelProps } from './label'

import '../../../../scss/react/form/row.scss'

interface RowProps extends LabelProps {
    children: React.ReactNode
    className?: string
    field?: Field
    label?: string
    valid?: boolean
    help?: string
    /** @deprecated */
    dutch?: never
    /** @deprecated */
    error?: never
}

const Row = ({ children, className, error, ...props }: RowProps) => {
    const hasError = !!(error || props.field?.errors)
    return (
        <ErrorBoundary>
            <div
                className={classnames('form-row', className, {
                    'form-row-error': hasError,
                })}
            >
                <Label {...props} />
                {(props.field?.help || props.help) && (
                    <p>{t(props.field?.help || props.help)}</p>
                )}
                {children}
                {hasError && (
                    <span className="form-row-error-message">
                        <WarningIcon />
                        {props.field?.errors?.map((error) => (
                            <Trans label={error} context="errors" markdown />
                        ))}
                        {!!error && (
                            <Trans label={error} context="errors" markdown />
                        )}
                    </span>
                )}
            </div>
        </ErrorBoundary>
    )
}

export const CombinedFields = ({ children }) => {
    return <div className="combined-form-fields">{children}</div>
}

export const CombinedRows = ({ children }) => {
    return <div className="combined-form-rows">{children}</div>
}

export default Row
