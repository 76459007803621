import { t } from '@zupr/i18n'
import { Field } from '@zupr/types/form'
import classnames from 'classnames'
import { InputHTMLAttributes, useCallback, useMemo } from 'react'

interface RenderInputProps extends InputHTMLAttributes<HTMLInputElement> {
    field: Field
    error?: string
}

export interface InputProps extends RenderInputProps {
    counter?: boolean
}

interface UsePlaceholderProps {
    field: Field
    placeholder?: string
    select?: boolean
    required?: boolean
}

export const usePlaceholder = ({
    field,
    placeholder,
    select,
    required,
}: UsePlaceholderProps) => {
    return useMemo(() => {
        let text

        if (typeof placeholder === 'string') {
            return t(placeholder)
        } else if (field.placeholder) {
            return t(field.placeholder)
        } else if (select) {
            text = t('Selecteer %{name}', {
                name: t(field.label)?.toLowerCase(),
            })
        } else if (field.label) {
            text = t('Voer %{name} in', {
                name: t(field.label)?.toLowerCase(),
            })
        }

        if (field.label && !field.required && !required) {
            text = `${text} (${t('optioneel')})`
        }

        return text
    }, [
        field.label,
        field.placeholder,
        placeholder,
        field.required,
        required,
        select,
    ])
}

const RenderInput = ({
    className,
    field,
    type,
    onBlur,
    maxLength,
    onChange,
    error,
    autoComplete = 'off',
    autoCapitalize,
    disabled,
    ...props
}: InputProps) => {
    const placeholder = usePlaceholder({
        ...props,
        field,
    })
    return (
        <input
            disabled={disabled}
            className={classnames(className, { error })}
            value={field.value == null ? '' : field.value}
            type={type}
            onChange={onChange}
            name={field.name}
            id={field.name}
            onBlur={onBlur}
            placeholder={placeholder}
            autoComplete={autoComplete}
            autoCapitalize={autoCapitalize}
            maxLength={maxLength || field.max_length}
        />
    )
}

// field is connected for now to test changes
const Input = ({
    field,
    counter,
    onChange,
    type = 'text',
    ...props
}: InputProps) => {
    const handleChange = useCallback(
        (ev) => {
            if (onChange) {
                return onChange(ev)
            }
            if (ev.defaultPrevented) return
            return field.setValue(ev.target.value)
        },
        [field, onChange]
    )

    const maxLength = useMemo(() => {
        if (field.max_length) return field.max_length

        if (
            field.max_digits &&
            field.value &&
            `${field.value}`.indexOf('.') > 0
        ) {
            return field.max_digits + 1
        }

        if (field.max_digits) return field.max_digits

        return null
    }, [field.max_digits, field.max_length, field.value])

    if (!counter)
        return (
            <RenderInput
                {...props}
                type={type}
                onChange={handleChange}
                maxLength={maxLength}
                field={field}
            />
        )

    return (
        <div className="input-counter">
            <RenderInput
                {...props}
                type={type}
                onChange={handleChange}
                maxLength={maxLength}
                field={field}
            />
            {field.max_length && counter && (
                <div className="max-length">
                    {field.max_length - (field.value || '').length}
                </div>
            )}
        </div>
    )
}

export default Input
